import { isUndefined, isNull, isEmpty } from 'lodash';
import { DEFAULT_TIMEZONE, DEFAULT_TIMEZONE_ABBREVIATION } from '../config/api_constants';

export const convertISOToDateTime = str => {
  return new Date(str);
};

/**
 * Function to convert date to particular timeZone date
 */
export const getDateInYYYYMMDDForTimezone = (str, timezone) => {
  if (isNull(str) || isUndefined(str)) return null;
  return new Date(str).toLocaleTimeString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timezone,
  });
};

/**
 * Function to convert date to particular timeZone datetime format YYYYMMDDHHMMSS
 */
export const getDateInYYYYMMDDHHMMSSForTimezone = (str, timezone) => {
  if (isNull(str) || isUndefined(str)) return null;
  return new Date(str).toLocaleTimeString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: timezone,
  });
};

export const getDate = str => {
  if (isNull(str) || isUndefined(str)) return null;
  const dateInJurisdictionTimezone = getDateInYYYYMMDDForTimezone(str, DEFAULT_TIMEZONE);
  return dateInJurisdictionTimezone.toString().split(',')[0];
};

export const getDateTime = str => {
  return isNull(str) || isUndefined(str)
    ? null
    : convertISOToDateTime(str).toLocaleString('en-US', { timeZone: DEFAULT_TIMEZONE });
};

export const getBusinessDate = str => {
  if (isEmpty(str) || isUndefined(str)) return null;
  const date = getDateInYYYYMMDDHHMMSSForTimezone(str, DEFAULT_TIMEZONE);
  return `${date} ${DEFAULT_TIMEZONE_ABBREVIATION}`;
};
