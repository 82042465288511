import { get, isEmpty } from 'lodash';
import { Button, Header, ProgressBar, StatusIndicator } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../constants/route_paths';
import StepsCompleted from '../../../../app/landing/sme/utils/StepsCompleted';
import { PROJECT_TEMPLATE_IDS } from '../../../constants/templateIds';
import './ComponentHeader.css';
import {
  convertISOToDateTime,
  getBusinessDate,
  getDateInYYYYMMDDForTimezone,
  getDateTime,
} from '../../../utils/date_time_util';
import { getStudyPeriod } from '../../../constants/study_period';
import { updateSurveyLockAction } from '../../../../app/landing/common/redux/UpdateSurveyLock';
import SURVEY_LOCK_ACTIONS from '../../../constants/survey_lock_actions';
import isSurveyLockAcquired from '../utils';
import { setDerivedClientId } from '../../../../redux/actions/derivedClientIdActions';
import { DEFAULT_TIMEZONE, DEFAULT_TIMEZONE_ABBREVIATION } from '../../../config/api_constants';

const ComponentHeader = ({
  headerDetails,
  navigateTo,
  surveyId,
  studyPeriod,
  updateSurveyLock,
}) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const surveyStatus = get(headerDetails, 'surveyStatus', null) || null;
  const tempateId = headerDetails.templateDocumentId;
  const stepsCompleted = StepsCompleted(get(headerDetails, 'userResponse', ''), tempateId);
  const navigateToSurveyPage = () => {
    const surveyRoute = getRoutePathWithStudyPeriod(ROUTE_PATH.SURVEY, studyPeriod);
    if (headerDetails.clientId) {
      dispatch(setDerivedClientId(headerDetails.clientId));
    }
    navigateTo({ pathname: surveyRoute, state: { headerDetails } });
  };
  const onClickHandler = () => {
    updateSurveyLock({
      onSuccess: navigateToSurveyPage,
      body: {
        clientId: headerDetails.clientId,
        surveyId,
        actionType: SURVEY_LOCK_ACTIONS.Acquire,
      },
    });
  };

  const DaysLeft = () => {
    const DateParts = convertISOToDateTime(get(headerDetails, 'surveyEndDate', ''));
    return Math.ceil((DateParts - Date.now()) / (1000 * 60 * 60 * 24));
  };

  const totalSteps = PROJECT_TEMPLATE_IDS.includes(tempateId) ? 6 : 7;
  const showStatus = () => {
    if (stepsCompleted !== 0)
      return (
        <ProgressBar
          status="in-progress"
          value={(stepsCompleted * 100) / totalSteps}
          description={`Step ${stepsCompleted} of ${totalSteps}`}
        />
      );
    return <div />;
  };

  useEffect(() => {
    const isDisabledButton = async () => {
      const currenDate = new Date();
      const jurisdictionSpecificDate = currenDate.toLocaleString('en-US', {
        timeZone: DEFAULT_TIMEZONE,
      });
      const surveyReleaseDate = getDateTime(get(headerDetails, 'surveyReleaseDate'));
      const surveyLockDetails = get(headerDetails, 'surveyLockDetails', {});
      if (new Date(jurisdictionSpecificDate).getTime() < new Date(surveyReleaseDate).getTime()) {
        return true;
      }

      if (surveyLockDetails) {
        isSurveyLockAcquired(surveyLockDetails).then(surveyLock => {
          setIsDisabled(surveyLock);
        });
      }
      return false;
    };

    isDisabledButton();
  }, [headerDetails]);

  const Actions = () => {
    switch (surveyStatus) {
      case 'Submitted': {
        const isRndUser = get(headerDetails, 'isRnDUser', false);
        if (isRndUser) {
          return (
            <Button variant="primary" onClick={navigateToSurveyPage}>
              View
            </Button>
          );
        }
        return null;
      }
      default: {
        return (
          <div className="surveyActions">
            <div className="surveyProgressBar">{showStatus()}</div>

            <Button
              variant="primary"
              onClick={onClickHandler}
              disabled={isDisabled}
              title={isDisabled && 'Survey is not released yet.'}
            >
              {' '}
              {isEmpty(get(headerDetails, 'userResponse')) ? 'Start' : 'Continue'}
            </Button>
          </div>
        );
      }
    }
  };
  const Status = () => {
    switch (surveyStatus) {
      case 'Submitted': {
        return <StatusIndicator type="success">Completed</StatusIndicator>;
      }
      default: {
        const days = DaysLeft();
        return (
          <StatusIndicator type="warning">
            {Math.abs(days)} day{days !== 1 ? 's' : ''} {days >= 0 ? 'left' : 'overdue'}
          </StatusIndicator>
        );
      }
    }
  };

  const dueDate = () => {
    switch (surveyStatus) {
      case 'Submitted': {
        return `Completed on ${getDateInYYYYMMDDForTimezone(
          get(headerDetails, 'lastUpdatedOn', null),
          DEFAULT_TIMEZONE,
        )} ${DEFAULT_TIMEZONE_ABBREVIATION}`;
      }
      default: {
        return `Due on ${getBusinessDate(get(headerDetails, 'surveyEndDate', null))}`;
      }
    }
  };
  return (
    <Header actions={<Actions />} info={<Status />} description={dueDate()}>
      {`${get(headerDetails, 'surveyType', null)} survey`}
    </Header>
  );
};

ComponentHeader.propTypes = {
  headerDetails: PropTypes.object,
  navigateTo: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  updateSurveyLock: PropTypes.func.isRequired,
  surveyId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    studyPeriod: getStudyPeriod(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateSurveyLock: updateSurveyLockAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ComponentHeader);
