import { Box, StatusIndicator } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DisplayEmployeeInformation from './DisplayEmployeeInformation';
import { ROUTE_PATH } from '../../../constants/route_paths';
import { getDateInYYYYMMDDForTimezone } from '../../../utils/date_time_util';
import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_TIMEZONE,
  DEFAULT_TIMEZONE_ABBREVIATION,
} from '../../../config/api_constants';

const PrimaryAssignee = ({ isPrimaryAssignee, assignee, currentDashboardRoutePath }) => {
  if (isPrimaryAssignee !== true) {
    return (
      <div>
        <Box color="text-body-secondary">Primary assignee</Box>
        <DisplayEmployeeInformation
          employees={[get(assignee, 'userId', null)]}
          clientId={get(assignee, 'clientId', DEFAULT_BUSINESS_ID)}
        />
      </div>
    );
  }
  const isRnD = currentDashboardRoutePath === ROUTE_PATH.RND_DASHBOARD;
  if (get(assignee, 'isSurveyReassigned', 'false') === 'true') {
    const { userAlias, lastReassignedBy, lastReassignedOn, lastReassignmentReason } = assignee;
    return (
      <StatusIndicator type="info">
        This survey was reassigned to {isRnD ? userAlias : 'you'} by {lastReassignedBy} on{' '}
        {getDateInYYYYMMDDForTimezone(lastReassignedOn, DEFAULT_TIMEZONE)}{' '}
        {DEFAULT_TIMEZONE_ABBREVIATION}
        {isRnD && `, because of "${lastReassignmentReason}".`}
      </StatusIndicator>
    );
  }
  return null;
};

PrimaryAssignee.propTypes = {
  isPrimaryAssignee: PropTypes.bool,
  assignee: PropTypes.object,
  currentDashboardRoutePath: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    currentDashboardRoutePath: get(state, 'currentDashboard.routePath'),
  };
};

export default connect(mapStateToProps, null)(PrimaryAssignee);
